import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CircularColor from '../Loader/CircularColor';
import { decrypt } from '../../common/encryptDecrypt';
import { toast } from "react-toastify";

const UnsbscribeMail = () => {
    const { data } = useParams();
    const decryptEmail = data
        ? decrypt({ data: data })
        : {};

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleUnsubscribe = async () => {
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}unsubscribedMail`, { email: decryptEmail });
            toast.success("You have successfully un-subscribed the newsletter.");
            navigate("/");
        } catch (error) {
            toast.error("Something went wrong! Please try again later.");
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='thank-you d-flex align-items-center justify-content-center'>
            {loading ? (
                <CircularColor />
            ) : (
                <button className='btn btn-primary mt-5' onClick={handleUnsubscribe}>Un-Subscribe</button>
            )}
        </div>
    );
};

export default UnsbscribeMail;
