import React from "react";
import LazyLoad from "react-lazy-load";
import notfoundimg from '../../assets/images/404.webp';
function Notfound() {
    return (
        <>
            <section className="not-found-section">
                <LazyLoad>
                    <img data-src={notfoundimg} src={notfoundimg} alt="Not found" height="1080" width="1920" />
                </LazyLoad>
            </section>
        </>
    )
}
export default Notfound;