import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MenuIcon } from "../../svgIcon/svgIcon";
import { FacebookIcon } from "../../svgIcon/svgIcon";
import { InstagramIcon } from "../../svgIcon/svgIcon";
import { TwitterIcon } from "../../svgIcon/svgIcon";
import logo from '../../../assets/images/logo.webp';
import LazyLoad from "react-lazy-load";

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    // Open the menu
    const openNav = () => {
        setMenuOpen(true);
        document.body.classList.add("overflow-hidden");
    
        if (window.innerWidth >= 992) {  // Check if the screen width is 992px or greater (Bootstrap lg breakpoint)
            document.body.style.paddingRight = "17px";
        }
    };
    
    // Close the menu
    const closeNav = () => {
        setMenuOpen(false);
        document.body.classList.remove("overflow-hidden");
    
        if (window.innerWidth >= 992) {  // Check if the screen width is 992px or greater (Bootstrap lg breakpoint)
            document.body.style.paddingRight = "0px";
        }
    };

    // Close menu if clicked outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            const menuElement = document.getElementById("menu");
            if (menuElement && !menuElement.contains(e.target)) {
                closeNav();
            }
        };

        if (menuOpen) {
            document.addEventListener("mouseup", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [menuOpen]);

    const [activeMenu, setActiveMenu] = useState('home');

    const handleMenuClick = (menuName) => {
        setActiveMenu(menuName);  // Update the state with the clicked menu name
        closeNav();
    };

    return (
        <>
            <header className="main-header">
                <div className="container-fluid">
                    <div className="header-inner d-flex align-items-center">
                        <div className="part-01">
                            <div className="humbergur_box" onClick={openNav}>
                                <MenuIcon />
                            </div>
                            <div className="sidenav" id="menu" style={{ left: menuOpen ? "0" : "-320px" }}>
                                <div className="sidenav-head d-flex align-items-center text-white bg-dark justify-content-between py-4 px-3">
                                    <h5 className="title">Menu</h5>
                                    <Link className="closebtn" onClick={closeNav}><span className="close"></span></Link>
                                </div>
                                <nav className="sidenav-body">
                                    <ul className="custom_sidebar_nav scrolltodiv">
                                        <li className={` ${activeMenu === 'home' ? 'active' : ''}`} onClick={() => handleMenuClick('home')}><Link to="/" title="Home" className={window.location.pathname === "/" ? "active" : ""}>Home</Link></li>
                                        <li className={` ${activeMenu === 'about-us' ? 'active' : ''}`} onClick={() => handleMenuClick('about-us')}><Link to="/about-us" title="About Us" className={window.location.pathname === "/about-us" ? "active" : ""}>About Us</Link></li>
                                        <li className={` ${activeMenu === 'Products' ? 'active' : ''}`} onClick={() => handleMenuClick('Products')}><Link to="/products" title="Products" className={window.location.pathname === "/products" ? "active" : ""}>Products</Link></li>
                                        <li className={` ${activeMenu === 'contact' ? 'active' : ''}`} onClick={() => handleMenuClick('contact')}><Link to="/contact" title="Contact Us" className={window.location.pathname === "/contact" ? "active" : ""}>Contact Us</Link></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className={`sidenav-back ${menuOpen ? "show" : ""}`} onClick={closeNav}></div>
                        </div>

                        <div className="part-02">
                            <div className="logo">
                                <Link to="/" title="Swadish Flavours Of India" className={` ${activeMenu === 'home' ? 'active' : ''}`} onClick={() => handleMenuClick('home')}>
                                    <LazyLoad>
                                        <img src={logo} alt="logo" width="160" height="82" />
                                    </LazyLoad>
                                </Link>
                            </div>
                        </div>

                        <div className="part-03">
                            <ul className="social-icon light d-flex align-items-center mt-2 justify-content-end">
                                <li><Link to="https://www.facebook.com/swadishworld/" target="_blank" className="icon facebook" title="Follow Us On: Facebook"><FacebookIcon /></Link></li>
                                <li><Link to="https://www.instagram.com/swadishworld" target="_blank" className="icon instagram" title="Follow Us On: Instagram"><InstagramIcon /></Link></li>
                                <li><Link to="https://x.com/swadishworld" target="_blank" className="icon twitter" title="Follow Us On: Twitter"><TwitterIcon /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;