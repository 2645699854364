import React from "react";
import { Link } from "react-router-dom";
import { ArrowIcon } from "../../svgIcon/svgIcon";
function Inner_banner(props) {
    return (
        <>
            <section className="inner-banner">
                <div className="container">
                    <div className="row text-center aos-init aos-animate" data-aos="fade-up">
                        <div className="col-12">
                            <h1 className="banner_title" data-title={props.title} style={{ "--fs": props.fs, "--top": props.top }} data-aos="fade">{props.title}</h1>
                            {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/" title="Home">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
                                </ol>
                            </nav> */}

                            <Link to="/" title="Back To home" className="btn btn-primary back-to-home">
                                <i className="ac-icon" data-icon="cookie"><ArrowIcon /></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Inner_banner;