import React from "react";
import LazyLoad from "react-lazy-load";
import Inner_banner from "../common-componets/Inner-Banner/Inner_banner";
import productimg from '../../assets/images/product.webp';
import refund from '../../assets/images/refund.webp';
import support from '../../assets/images/support.webp';
import about1 from '../../assets/images/about1.webp';
import about2 from '../../assets/images/about2.webp';
import about3 from '../../assets/images/about3.webp';
import showdown from '../../assets/images/shop-down-effect.webp';
import product from '../../assets/images/product-bg-text.webp';
import plant from '../../assets/images/shop-sideimg.webp';
import shope from '../../assets/images/shop-up-effect.webp';
import { Helmet } from "react-helmet";
function AboutPage() {
    return (
        <>
            <Helmet>
                <title>About Us - Swadish World | Our Journey in Authentic Indian Cuisine</title>
                <meta name="description" content="Learn about Swadish World and our passion for bringing authentic Indian flavors to your table. Discover our journey, values, and commitment to providing high-quality Indian cuisine." />
                <meta name="keywords" content="About Swadish World, Indian cuisine, authentic Indian food, Swadish journey, Indian restaurant, Indian food culture, traditional recipes, Indian meals" />
                <meta property="og:title" content="About Us - Swadish World | Our Journey in Authentic Indian Cuisine" />
                <meta property="og:description" content="Discover the story behind Swadish World and our dedication to offering the finest Indian cuisine. Learn about our values and our mission to bring authentic Indian dishes to your table." />
                <meta name="twitter:title" content="About Us - Swadish World | Our Journey in Authentic Indian Cuisine" />
                <meta name="twitter:description" content="Explore Swadish World’s journey in the world of authentic Indian cuisine. Learn about our commitment to bringing traditional flavors and high-quality meals to your home." />
            </Helmet>
            <Inner_banner title="About Us" fs="7vw" top="-68px" />
            <section className="about-us-page position-relative">
            <div className="shop_plant--sideimg d-none d-xl-block">
                    <LazyLoad>
                       <img src={plant} alt="plant" width="315" height="403" />
                    </LazyLoad>
                </div>
                <div className="shop_up--effect d-none d-lg-block">
                     <LazyLoad>
                         <img src={shope} alt="effect" width="262" height="267" />
                    </LazyLoad>
                </div>
                <div className="container">
                    <div className="row about-row">
                        <div className="col-md-6 a-discri">
                            <div className="content">
                                <h2 className="cm-title aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">About us</h2>
                                <div className="descri aos-init aos-animate" data-aos="fade-right" data-aos-delay="300">
                                    <p>Our brand name "Swadish", derived from the Hindi word "Swadish," meaning delicious and wholesome food with a desirable taste.</p>
                                    <p>We welcome you to the world of "Swadish", where every bite is a journey to the heart of Indian cuisine. At Swadish, we take pride in crafting ready-to-eat meals and cooking ingredients that bring the vibrant flavours of India straight to your table.  </p>
                                    <p>With a focus on freshness, quality, and authenticity, Swadish offers an array of Indian spices, chutneys, pickles, pastes, and other culinary essentials. Each product is carefully prepared to ensure it retains the rich flavours and traditional essence that define Indian cuisine. Whether you're looking for a quick meal solution or premium ingredients to enhance your cooking, Swadish is dedicated to providing you with exceptional products that make every meal memorable.</p>
                                    <p>Experience the true taste of India with Swadish—where tradition meets convenience, and every dish is a celebration of flavour.</p>
                                </div>
                            </div>
                            <div className="aboutc-img">
                                <div className="aimg-conetnt aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                    <LazyLoad>
                                        <img src={productimg} data-src={productimg} width="64" height="64" />
                                    </LazyLoad>
                                    <span className="icon-text">Top Quality Products</span>
                                </div>
                                <div className="aimg-conetnt aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                    <LazyLoad>
                                        <img src={refund} data-src={refund} width="64" height="64" />
                                    </LazyLoad>
                                    <span className="icon-text">Transparency of Competitive Pricing</span>
                                </div>
                                <div className="aimg-conetnt aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
                                    <LazyLoad>
                                        <img src={support} data-src={support} width="64" height="64" />
                                    </LazyLoad>
                                    <span className="icon-text">24/7 Support</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-images">
                                <div className="about-img1 a-images aos-init aos-animate" >
                                    <LazyLoad>
                                        <img src={about1} data-src={about1} width="480" height="480" data-aos="fade-down" data-aos-delay="200"/>
                                    </LazyLoad>
                                </div>
                                <div className="about-img2 a-images aos-init aos-animate" >
                                    <LazyLoad>
                                        <img src={about2} data-src={about2} width="399" height="399" data-aos="fade-up" data-aos-delay="300"/>
                                    </LazyLoad>
                                </div>
                                <div className="about-img3 a-images aos-init aos-animate" >
                                    <LazyLoad>
                                        <img src={about3} data-src={about3} width="485" height="479" data-aos="fade-up" data-aos-delay="400"/>
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shop_down--effect d-none d-lg-block">
                        <LazyLoad>
                            <img alt="effect" src={showdown} width="185" height="154" />
                        </LazyLoad>
                    </div>
            </section>
        </>
    )
}
export default AboutPage;